import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // this is causing a double api call for the /jobs endpoint ignore this in production this won't occur
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
