import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Alert,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

const SignUpForm = () => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [googleScriptLoaded, setGoogleScriptLoaded] = useState(false);

  const navigate = useNavigate();

  // Load Google Sign-In script
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    script.onload = () => setGoogleScriptLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Initialize Google Sign-In
  useEffect(() => {
    const handleGoogleSignUp = async (response) => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/google-signup`,
          {
            credential: response.credential,
          }
        );

        // Store token and redirect
        localStorage.setItem("token", res.data.access_token);
        setSuccess("Sign up successful. Redirecting to jobs...");
        setError("");
        setTimeout(() => {
          navigate("/jobs");
        }, 3000);
      } catch (err) {
        console.error(err);
        if (err.response) {
          setError(
            err.response.data.message ||
              err.response.data.error ||
              "Sign-up failed"
          );
        } else if (err.request) {
          setError("No response from server");
        } else {
          setError("An error occurred during Google sign-up");
        }
      }
    };

    if (googleScriptLoaded && window.google) {
      window.google.accounts.id.initialize({
        client_id:
          "758941406811-4s1b90kq5hji7f1cg2sd9r5selcorv7t.apps.googleusercontent.com",
        callback: handleGoogleSignUp,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("googleSignUpDiv"),
        { theme: "outline", size: "large", text: "signup_with"}
      );
    }
  }, [googleScriptLoaded, navigate]);

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Validation
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (!termsAccepted || !privacyAccepted) {
      setError(
        "You must accept the Terms and Conditions and Privacy Policy to sign up"
      );
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/signup`, {
        first_name: firstName,
        email,
        password,
      });

      setSuccess("Sign up successful. Redirecting to login...");
      setError("");
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (err) {
      console.error(err);
      if (err.response) {
        setError(
          err.response.data.message ||
            err.response.data.error ||
            "Sign-up failed"
        );
      } else if (err.request) {
        setError("No response from server");
      } else {
        setError("An error occurred during sign-up");
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={5}>
        <IconButton
          onClick={() => navigate("/jobs")}
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          <CloseIcon style={{ fontSize: 32 }} />
        </IconButton>
        <Typography variant="h4" align="center" gutterBottom>
          Sign Up
        </Typography>
        <form onSubmit={handleSignUp}>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <TextField
            label="Confirm Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  required
                />
              }
              label={
                <span>
                  I agree to the{" "}
                  <Link href="/terms" target="_blank">
                    Terms and Conditions
                  </Link>
                </span>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={privacyAccepted}
                  onChange={(e) => setPrivacyAccepted(e.target.checked)}
                  required
                />
              }
              label={
                <span>
                  I agree to the{" "}
                  <Link href="/privacy" target="_blank">
                    Privacy Policy
                  </Link>
                </span>
              }
            />
          </FormGroup>
          {success && (
            <Alert severity="success" align="center">
              {success}
            </Alert>
          )}
          {error && (
            <Typography color="error" align="center">
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "20px" }}
          >
            Sign Up
          </Button>
        </form>
        <Box mt={2} textAlign="center">
          <Typography>
            Already have an account? <Link to="/login">Log in here</Link>
          </Typography>
        </Box>
        <Typography mt={2} variant="h6" align="center">
          OR
        </Typography>
        <Box mt={2} mb={2}>
          <div id="googleSignUpDiv"></div>
        </Box>
      </Box>
    </Container>
  );
};

export default SignUpForm;
