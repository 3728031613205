import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';

const JobLinkTester = () => {
    const [links, setLinks] = useState('');
    const [result, setResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setResult(null);

        // Split links by newline and filter out empty lines
        const urlList = links.split('\n').filter(link => link.trim());

        try {
            // Get the token from localStorage
            const token = localStorage.getItem('token');
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/scrape/jobs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    urls: urlList
                })
            });

            const data = await response.json();
            setResult(data);
        } catch (error) {
            setResult({ error: error.message });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ maxWidth: '800px', mx: 'auto', p: 4 }}>
            <Typography variant="h4" gutterBottom>
                Job Link Tester
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    multiline
                    rows={15}
                    value={links}
                    onChange={(e) => setLinks(e.target.value)}
                    placeholder="https://example.com/job1&#10;https://example.com/job2"
                    label="Enter Job Links (one per line)"
                    variant="outlined"
                    sx={{ mb: 2 }}
                />
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isLoading}
                    fullWidth
                >
                    {isLoading ? 'Processing...' : 'Test Links'}
                </Button>
            </form>

            {result && (
                <div className="mt-8">
                    <h2 className="text-xl font-semibold mb-4">Results:</h2>
                    <div className="bg-gray-50 p-4 rounded-md">
                        <h3 className="font-medium mb-2">Summary:</h3>
                        <ul className="list-disc pl-5 mb-4">
                            <li>Total Links: {result.summary?.total || 0}</li>
                            <li>Successful: {result.summary?.successful || 0}</li>
                            <li>Filtered: {result.summary?.filtered || 0}</li>
                            <li>Existing: {result.summary?.existing || 0}</li>
                            <li>Rejected: {result.summary?.rejected || 0}</li>
                            <li>Errors: {result.summary?.errors || 0}</li>
                        </ul>

                        <h3 className="font-medium mb-2">Detailed Results:</h3>
                        <div className="space-y-2">
                            {result.results?.map((item, index) => (
                                <div 
                                    key={index}
                                    className={`p-2 rounded ${
                                        item.status === 'success' ? 'bg-green-100' :
                                        item.status === 'filtered' ? 'bg-yellow-100' :
                                        item.status === 'rejected' ? 'bg-orange-100' :
                                        item.status === 'error' ? 'bg-red-100' :
                                        'bg-gray-100'
                                    }`}
                                >
                                    <p className="font-medium">{item.url}</p>
                                    <p>Status: {item.status}</p>
                                    {item.message && <p>Message: {item.message}</p>}
                                    {item.title && <p>Title: {item.title}</p>}
                                    {item.company && <p>Company: {item.company}</p>}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </Box>
    );
};

export default JobLinkTester;