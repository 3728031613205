import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import { jwtDecode } from "jwt-decode";
import { useNavigate, Link } from "react-router-dom"; // Import Link
import axios from "axios";
import logo from './sfd-logo.png';

export default function MenuAppBar() {
  const [auth, setAuth] = useState(false);
  const [userName, setUserName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setAuth(true);
        setUserName(decodedToken.first_name);
      } catch (error) {
        console.error("Invalid token");
        setAuth(false);
        setUserName("");
      }
    }
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setAuth(false);
    setUserName("");
    navigate("/login");
  };

  const handleDeleteAccount = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete your account? This action cannot be undone.");
    if (confirmDelete) {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete-account`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200) {
            alert("Account deleted successfully.");
            handleLogout(); // Log the user out after deletion
          } else {
            alert("Failed to delete account.");
          }
        } catch (error) {
          console.error("Error deleting account:", error);
          alert("An error occurred while trying to delete the account.");
        }
      }
    }
  };

  return (
    <Box mt={2} sx={{ flexGrow: 1 }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Left: Clickable Logo */}
        <Link to="/jobs" style={{ textDecoration: 'none' }} onClick={() => window.location.reload()}>
          <img src={logo} alt="SearchForDev Logo" style={{ height: '45px', cursor: 'pointer' }} />
        </Link>
        
        {/* Right: Profile Section */}
        {auth ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar sx={{ bgcolor: "#3f51b5", fontSize: 25 }}>
                {userName.charAt(0)}
              </Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleDeleteAccount}>Delete Account</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="primary" // Uses primary color from the theme for background
            onClick={handleLogin}
          >
            Login
          </Button>
        )}
      </Toolbar>
    </Box>
  );
}
